import { Navigate } from "react-router-dom";

import Header from "components/Header";
import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "../../components/ArgonTypography";

import useAuth from "utils/useAuth";

import Grid from "@mui/material/Grid";
import {CircularProgress, FormControlLabel, FormGroup, Radio, RadioGroup} from "@mui/material";
import {useEffect, useState} from "react";
import {UsersApi} from "../../api/BackendApi/Users";
import {create} from "../../api/BackendApi/CreateTaf";
import Card from "@mui/material/Card";
import SaveIcon from '@mui/icons-material/Save';

import ArgonInput from "../../components/ArgonInput";
import ArgonButton from "../../components/ArgonButton";
import ArgonDropzone from "../../components/ArgonDropzone";
import {enqueueSnackbar} from "notistack";
import IconButton from "@mui/material/IconButton";
import {useArgonController} from "../../context";
import Checkbox from "@mui/material/Checkbox";

function CreateTaf() {

  const [user, setUser] = useState(null);
  const [saving, setSaving] = useState(false);
  const [customer, setCustomer] = useState("");
  const isLoggedIn = useAuth().ensureLoggedIn();
  const colors = ["primary", "dark", "info", "success", "warning", "error"];

  const [taf, setTaf] = useState(true);
  const [orch, setOrch] = useState(true);
  const [node, setNode] = useState(true);
  const [taffy, setTaffy] = useState(false);
  const [appExpl, setAppExpl] = useState(false);
  const [appMonitoring, setAppMonitoring] = useState(false);
  const [genTest, setGenTest] = useState(false);
  const [integMngmt, setIntegMngmt] = useState(false);
  const [dbType , setDBType] = useState('Serveless');

  const [controller] = useArgonController();
  const { templateColor } = controller;
  const dropzoneOptions = {
    addRemoveLinks: true,
    url: "TODO url",
    maxFiles: 1,
    multiple: false,
    autoProcessQueue: true,
    method: "POST",
    withCredentials: true,
    headers: {
      "Authorization": "Bearer " + (localStorage.getItem("jwt-token") || sessionStorage.getItem("jwt-token"))
    },

    init: function () {

      this.on("removedfile", (file) => {
        // setDropzoneFiles((previousFiles) => previousFiles.filter((f) => f.upload.filename !== file.upload.filename))
      });

      this.on("addedfile", (file) => {
        // setIsUploading(true);
      });

      this.on("complete", (file) => {
        console.log(file)
        onClose();
        if (file.status === 'error') {
          enqueueSnackbar('There was an error updating the profile image.', { variant: 'error' })
        } else if (file.status === 'canceled') {
          enqueueSnackbar('Image upload canceled', { variant: 'warning' })
        } else {
          enqueueSnackbar('Profile image changed.', { variant: 'success' })
        }

      });

      this.on("maxfilesexceeded", function (file) {
        this.removeAllFiles();
        this.addFile(file);
      });
    },
  };

  useEffect(() => {
    async function loadData() {
      let response = await UsersApi.getMe();
      setUser(response.data.user);
    }
    loadData().then(r => console.log("Information loaded"));
  }, []);

  const handleSubmitCreateTaf = async () => {
    setSaving(true);
    const services = {"taf": taf, "orch": orch, "node": node, "taffy": taffy, "appExpl": appExpl, "appMonitoring": appMonitoring, "genTest": genTest, "integMngmt": integMngmt};
    let response = await create(customer, services, dbType);
    setSaving(false);
    if (response.data.created) {
      enqueueSnackbar('TAF created successfully', { variant: 'success' })
    } else {
      enqueueSnackbar('There was an error creating the TAF', {variant: 'error'})
    }
  };

  const handleChangeDBType = (event) => {
    setDBType(event.target.value);
  };

  if (!isLoggedIn) {
    return <Navigate replace to="/sign-in" />;
  }

  let body = <Grid container justifyContent="center" sx={{marginTop: 40, marginBottom: 20}}>
                        <CircularProgress></CircularProgress>
                      </Grid>

  if (user != null) {
    body = (
      <ArgonBox mt={10} mb={3}>
        <ArgonBox mb={3}>
          <Card sx={{ p: 3 }}>
            <Grid container>
              <Grid item xs={12} >
                <ArgonBox>
                  <ArgonTypography variant="h2" sx={"text-align: center"}>New Test Automation Framework</ArgonTypography>
                </ArgonBox>
              </Grid>
            </Grid>
            <ArgonBox component="form" role="form">
              <ArgonTypography fontSize={14} sx={{ fontWeight : "bold" }}>Customer Name*:</ArgonTypography>
              <ArgonBox mb={2}>
                <ArgonInput
                    placeholder="ex. Prada, Costco, Easyjet, etc"
                    value={customer ?? ''}
                    size="medium"
                    onChange={(e) => setCustomer(e.target.value)}
                />
              </ArgonBox>
            </ArgonBox>
            <Grid container spacing={10}>
              <Grid item xs={12} md={3}>
                <ArgonBox component="form" role="form">
                  <ArgonTypography fontSize={14} sx={{ fontWeight : "bold" }}>Customer Logo (TODO):</ArgonTypography>
                  <ArgonBox mb={2}>
                    <div className="dropmehere" id="dropmehere">
                      <ArgonDropzone options={dropzoneOptions} />
                    </div>
                  </ArgonBox>
                </ArgonBox>
              </Grid>
              <Grid item xs={12} md={4}>
                <ArgonBox component="form" role="form">
                  <ArgonTypography fontSize={14} sx={{ fontWeight : "bold" }}>Services:</ArgonTypography>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormGroup>
                        <FormControlLabel control={<Checkbox checked={taf} onChange={(e) => setTaf(e.target.checked)}/>} label="TAF" />
                        <FormControlLabel control={<Checkbox checked={orch} onChange={(e) => setOrch(e.target.checked)}/>} label="Orchestrator" />
                        <FormControlLabel control={<Checkbox checked={node} onChange={(e) => setNode(e.target.checked)}/>} label="Node" />
                        <FormControlLabel control={<Checkbox checked={taffy} onChange={(e) => setTaffy(e.target.checked)}/>} label="Taffy" />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={6}>
                      <FormGroup>
                        <FormControlLabel control={<Checkbox checked={appMonitoring} onChange={(e) => setAppMonitoring(e.target.checked)}/>} label="Application Monitoring" />
                        <FormControlLabel control={<Checkbox checked={appExpl} onChange={(e) => setAppExpl(e.target.checked)}/>} label="Application Explorer" />
                        <FormControlLabel control={<Checkbox checked={genTest} onChange={(e) => setGenTest(e.target.checked)}/>} label="Generative Test" />
                        <FormControlLabel control={<Checkbox checked={integMngmt} onChange={(e) => setIntegMngmt(e.target.checked)}/>} label="Integration Mngmt Tool" />
                      </FormGroup>
                    </Grid>
                  </Grid>
                </ArgonBox>
              </Grid>
              <Grid item xs={12} md={2}>
                <ArgonBox component="form" role="form">
                  <ArgonTypography fontSize={14} sx={{ fontWeight : "bold" }}>Database:</ArgonTypography>
                  <Grid container>
                    <Grid item xs={12}>
                      <RadioGroup
                          row
                          aria-labelledby="row-radio-mongodb-type"
                          name="row-radio-mongodb-type"
                          value={dbType}
                          onChange={handleChangeDBType}
                      >
                        <FormControlLabel value="Serveless" control={<Radio />} label="Serveless (FREE)" />
                        <FormControlLabel value="Dedicated" control={<Radio />} label="Dedicated" />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </ArgonBox>
              </Grid>
              <Grid item xs={12} md={3} textAlign={"center"}>
                <ArgonBox component="form" role="form">
                  <ArgonTypography fontSize={14} sx={{ fontWeight : "bold" }}>Principal Color (TODO)</ArgonTypography>
                  <ArgonBox mb={0.5}>
                    {colors.map((color) => (
                        <IconButton
                            key={color}
                            sx={({ borders: { borderWidth }, palette: { white, dark }, transitions }) => ({
                              width: "24px",
                              height: "24px",
                              padding: 0,
                              border: `${borderWidth[1]} solid ${white.main}`,
                              borderColor: templateColor === color && dark.main,
                              transition: transitions.create("border-color", {
                                easing: transitions.easing.sharp,
                                duration: transitions.duration.shorter,
                              }),
                              backgroundImage: ({ functions: { linearGradient }, palette: { gradients } }) =>
                                  linearGradient(gradients[color].main, gradients[color].state),

                              "&:not(:last-child)": {
                                mr: 1,
                              },

                              "&:hover, &:focus, &:active": {
                                borderColor: dark.main,
                              },
                            })}
                            //onClick={() => setSidenavColor(dispatch, color)}
                        />
                    ))}
                  </ArgonBox>
                </ArgonBox>
                <ArgonBox component="form" role="form">
                  <ArgonTypography fontSize={14} sx={{ fontWeight : "bold" }}>Secondary Color (TODO)</ArgonTypography>
                  <ArgonBox mb={0.5}>
                    {colors.map((color) => (
                        <IconButton
                            key={color}
                            sx={({ borders: { borderWidth }, palette: { white, dark }, transitions }) => ({
                              width: "24px",
                              height: "24px",
                              padding: 0,
                              border: `${borderWidth[1]} solid ${white.main}`,
                              borderColor: templateColor === color && dark.main,
                              transition: transitions.create("border-color", {
                                easing: transitions.easing.sharp,
                                duration: transitions.duration.shorter,
                              }),
                              backgroundImage: ({ functions: { linearGradient }, palette: { gradients } }) =>
                                  linearGradient(gradients[color].main, gradients[color].state),

                              "&:not(:last-child)": {
                                mr: 1,
                              },

                              "&:hover, &:focus, &:active": {
                                borderColor: dark.main,
                              },
                            })}
                            //onClick={() => setSidenavColor(dispatch, color)}
                        />
                    ))}
                  </ArgonBox>
                </ArgonBox>
                <ArgonBox component="form" role="form">
                  <ArgonTypography fontSize={14} sx={{ fontWeight : "bold" }}> Tertiary Color (TODO)</ArgonTypography>
                  <ArgonBox mb={0.5}>
                    {colors.map((color) => (
                        <IconButton
                            key={color}
                            sx={({ borders: { borderWidth }, palette: { white, dark }, transitions }) => ({
                              width: "24px",
                              height: "24px",
                              padding: 0,
                              border: `${borderWidth[1]} solid ${white.main}`,
                              borderColor: templateColor === color && dark.main,
                              transition: transitions.create("border-color", {
                                easing: transitions.easing.sharp,
                                duration: transitions.duration.shorter,
                              }),
                              backgroundImage: ({ functions: { linearGradient }, palette: { gradients } }) =>
                                  linearGradient(gradients[color].main, gradients[color].state),

                              "&:not(:last-child)": {
                                mr: 1,
                              },

                              "&:hover, &:focus, &:active": {
                                borderColor: dark.main,
                              },
                            })}
                            //onClick={() => setSidenavColor(dispatch, color)}
                        />
                    ))}
                  </ArgonBox>
                </ArgonBox>
              </Grid>
            </Grid>
            <ArgonBox display="flex" justifyContent="center" mt={2}>
              <ArgonBox mr={1.5}>
                <ArgonButton color="primary"  disabled={customer === ""} onClick={handleSubmitCreateTaf}>
                  {saving ? <CircularProgress color="white" size={14} /> : <SaveIcon />}
                  &nbsp; {saving ? "Creating..." : "Create"}
                </ArgonButton>
              </ArgonBox>
            </ArgonBox>
          </Card>
        </ArgonBox>
      </ArgonBox>
    );
  }

  return (
    <DashboardLayout>
      <Header />
        {body}
      <Footer />
    </DashboardLayout>
  );
}

export default CreateTaf;
